import { JSONResume } from '../interface/jsonResume';
import Alpine from 'alpinejs';

async function main() {
	var resume = await fetchResumeJson();
	var formattedResume = formatResume(resume);
	setupAlpineJS(formattedResume);
	Alpine.start();
}

function formatResume(resume: JSONResume): JSONResume {
	console.log('Formatting resume');

	// EG set empty enddate to "ongoing" for work
	// format the list of work.highlights to be comma+space separated (same for intrests)
	return resume;
}
async function fetchResumeJson(): Promise<JSONResume> {
	console.log('Fetching resume JSON');

	var resume = fetch(
		'https://api.github.com/gists/2f1784c362aee1de588d32ab060117db'
	)
		.then((response) => response.json())
		.then((result) => {
			var resume = JSON.parse(result.files['resume.json'].content);
			return resume;
		})
		.catch((error) => console.log('error', error));

	return await resume;
}

function setupAlpineJS(resume: JSONResume) {
	console.log('Setting up AlpineJS');

	window.Alpine = Alpine;

	Alpine.store('resume', {
		resume: 'resumeJson',
		basic: resume.basics,
		work: resume.work,
		certificates: resume.certificates,
		volunteer: resume.volunteer,
		education: resume.education,
		awards: resume.awards,
		publications: resume.publications,
		skills: resume.skills,
		languages: resume.languages,
		interests: resume.interests,
		references: resume.references,
		projects: resume.projects,
		meta: resume.meta,
	});

	document.addEventListener('alpine:init', () => {
		Alpine.data('profileHandler', () => ({
			profileImage(profile: string) {
				const profileImageMap = new Map();
				profileImageMap.set('Email', 'icons/mail.webp');
				profileImageMap.set('Website', 'icons/world-wide-web.webp');
				profileImageMap.set('Bitbucket', 'icons/bitbucket.webp');
				profileImageMap.set('Github', 'icons/github.webp');
				profileImageMap.set('LinkedIn', 'icons/linkedin.webp');
				profileImageMap.set('Google Developer', 'icons/google.webp');
				profileImageMap.set('TryHackMe', 'icons/padlock.webp');
				profileImageMap.set(
					'Skillsoft Badges',
					'icons/logical-thinking.webp'
				);
				profileImageMap.set('Microsoft Learn', 'icons/microsoft.webp');
				profileImageMap.set('Default', 'icons/profile.webp');

				if (profileImageMap.has(profile)) {
					console.log(profile + ': ' + profileImageMap.get(profile));

					return profileImageMap.get(profile);
				} else {
					console.log(
						profile + ': ' + profileImageMap.get('Default')
					);

					return profileImageMap.get('Default');
				}
			},
		}));
	});
}

main();
